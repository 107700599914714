@import './mixins.scss';

.center {
    text-align: center;
    font-weight: bold;
}

html {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to('mobile') {
        // this is everything bigger than "mobile"
        font-size: 0.9rem;
        margin: 1.5em;
    }

    @include respond-to('small') {
        font-size: 1rem;
    }

    @include respond-to('medium') {
        font-size: 1.25rem;
    }
}

.invisible {
    visibility: hidden;
}

.undisplay {
    display: none;
}

.codeblock pre {
    background: #d3d3d3;
    padding: 0.5em;
    font-size: 0.65rem;

    border-radius: 0.5em;
    box-shadow: 5px 5px 10px #292826;
    @include respond-to('mobile') {
        font-size: 0.7rem;
    }

    @include respond-to('medium') {
        font-size: 0.785rem;
    }

    @include respond-to('large') {
        font-size: 1.1rem;
    }
}

.flexblock {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    @include respond-to('small') {
        flex-direction: row;
    }
}

.skip-button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    box-shadow: 5px 5px 10px #292826;
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
}

.instructions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.85rem;

    @include respond-to('mobile') {
        font-size: 1rem;
    }

    @include respond-to('small') {
        font-size: 1.15rem;
    }
}

.arrow {
    margin: 0.2em;
    transform: rotate(90deg);
    font-size: 2rem;
    @include respond-to('mobile') {
        margin: 0.25em;
        font-size: 2.65rem;
    }

    @include respond-to('small') {
        margin: 0.3em;
        font-size: 3rem;
        transform: rotate(0deg);
    }

    @include respond-to('large') {
        font-size: 3rem;
        transform: rotate(0deg);
    }
}

.loading {
    text-align: center;

    font-size: 0.75rem;

    @include respond-to('mobile') {
        font-size: 0.9rem;
    }

    @include respond-to('small') {
        font-size: 1.15rem;
    }
}
